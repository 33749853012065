<template>
  <!-- <div style="background:blue; height:100vh;" class="fill-height">
      <h1>Siti WEB</h1>
  </div> -->

  <!-- <div>
      <link href="https://fonts.googleapis.com/css?family=Josefin+Sans" rel="stylesheet"> -->

  <div class="container-fluid">
    <div class="main_header_background">
      <!-- <div class="background"> -->
      <!-- <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div> -->
      <!-- <div class="cube2-tl"></div> -->
      <!-- <div class="cube2-tr"></div> -->
      <!-- <div class="cube2-c"></div> -->
      <!-- <div class="cube2-bl"></div> -->
      <!-- <div class="cube2-br"></div> -->
    </div>

    <div class="body-container">
      <v-row no-gutters class="">
        <v-col cols="12" class="">
          <img
            data-aos="fade-right"
            draggable="false"
            :src="sf_logo_w"
            :srcset="sf_logo_w_2x"
          />
        </v-col>

        <v-col cols="12" sm="7" class="blue">
          <h1>Titolo</h1>
          <h2>Prova gratuitamente per un mese</h2>
          <h3>Caratteristiche</h3>

          <ul>
            <li><h4>Comunica in modo efficace con i tuoi collaboratori</h4></li>
          </ul>
        </v-col>
        <v-col cols="12" sm="5" class="red"></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  props: {},
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {
    AOS.init();
  },
  created() {},
  computed: {
    sf_logo_bw() {
      return require("@/assets/graphics/sf-logo-black-yellow_64.png");
    },
    sf_logo_bw_2x() {
      return require("@/assets/graphics/sf-logo-black-yellow_128.png") + " 2x";
    },
    sf_logo_w() {
      return require("@/assets/graphics/sf-logo-white-yellow_64.png");
    },
    sf_logo_w_2x() {
      return require("@/assets/graphics/sf-logo-white-yellow_128.png") + " 2x";
    },
  },
  watch: {},
};
</script>

<style scoped>
.main_header_background {
  position: relative;
  min-height: 90vh;
  /* box-shadow: 0px 0px 20px black; */
  background-color: #000000a8;
  z-index: 1;
}

.main_header_background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  /* filter: grayscale(100%); */
  background-image: url("../../../../assets/graphics/landing/backgrounds/safety_01.png");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* -------------- */

.container-fluid {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
}

.body-container {
  z-index: 2;
  /* background-color: #000000a8; */
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* small screen */
@media only screen and (max-width: 600px) {
  .body-container {
    width: 95vw;
    height: 95vh;
    top: 1rem;
  }
}

/* large screen */
@media only screen and (min-width: 600px) {
  .body-container {
    width: 100vw;
    height: 85vh;
    top: 1rem;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

/* ============= Animation background ========= */

/* --- */

.background {
  background: linear-gradient(132deg, #000000, #212121, #000000, #2b2b2b);
  background-size: 400% 400%;
  animation: Gradient 8s ease infinite;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0px;
}
.cube {
  position: absolute;
  top: 10vh;
  left: 10vw;
  width: 10px;
  height: 10px;
  border: solid 1px #d7d4e4;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #fff;
}
.cube:nth-child(2) {
  animation-delay: 0.75s;
  left: 85vw;
  top: 85vh;
}
.cube:nth-child(3) {
  animation-delay: 1.5s;
  left: 0vw;
  top: 90vh;
}
.cube:nth-child(4) {
  animation-delay: 2.25s;
  left: 50vw;
  top: 50vh;
}
.cube:nth-child(5) {
  animation-delay: 3s;
  left: 90vw;
  top: 15vh;
}
.cube:nth-child(6) {
  animation-delay: 3.75s;
  left: 50vw;
  top: 25vh;
}

.cube:nth-child(7) {
  animation-delay: 4.5s;
  left: 30vw;
  top: 50vh;
}
.cube:nth-child(8) {
  animation-delay: 5.25s;
  left: 75vw;
  top: 40vh;
}

.cube:nth-child(9) {
  animation-delay: 6s;
  left: 50vw;
  top: 80vh;
}

/* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Animated Rotating Cubes */
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(40) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

/* ------------------ */
/* .cube2-tl .cube-c .cube-br {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
} */

/* .cube2:nth-child(1) { */
.cube2-tl {
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
  animation-delay: 0s;
  left: 0vw;
  top: 0vh;
  border-image: linear-gradient(var(--angle), #ffff00, #ffff0079, #8d9400) 1;
  animation: 10s rotate-tl linear infinite alternate;
}

.cube2-tr {
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
  animation-delay: 0s;
  left: 100vw;
  top: 0vh;
  border-image: linear-gradient(var(--angle), #ffff00, #ffff0079, #8d9400) 1;
  animation: 10s rotate-tr linear infinite alternate;
}

.cube2-c {
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
  animation-delay: 0.5s;
  left: 50vw;
  top: 50vh;
  border-image: linear-gradient(var(--angle), #ffff00, #ffff0079, #8d9400) 1;
  animation: 8s rotate-c linear infinite;
}

.cube2-bl {
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
  animation-delay: 0.75s;
  left: 0vw;
  top: 100vh;
  border-image: linear-gradient(var(--angle), #ffff00, #ffff0079, #8d9400) 1;
  animation: 10s rotate-bl linear infinite alternate;
}

.cube2-br {
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  --angle: 0deg;
  width: 50vmin;
  height: 50vmin;
  border: 1rem solid;
  animation-delay: 0.75s;
  left: 100vw;
  top: 100vh;
  border-image: linear-gradient(var(--angle), #ffff00, #ffff0079, #8d9400) 1;
  animation: 10s rotate-br linear infinite alternate;
}

@keyframes rotate-tl {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-width: 1rem;
  }
  to {
    transform: scale(6) rotate(360deg) translate(-50%, -50%);
    opacity: 0;
    --angle: 360deg;
    border-width: 1.5rem;
    /* 
    left: 100vw;
    top: 100vh; */
  }
}

@keyframes rotate-tr {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-width: 1rem;
  }
  to {
    transform: scale(6) rotate(360deg) translate(-50%, -50%);
    opacity: 0;
    --angle: 360deg;
    border-width: 1.5rem;
  }
}

@keyframes rotate-c {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-width: 1rem;
  }
  to {
    transform: scale(2) rotate(360deg) translate(-100%, -50%);
    opacity: 0;
    --angle: 360deg;
    border-width: 1.5rem;
  }
}

@keyframes rotate-bl {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-width: 1rem;
  }
  to {
    transform: scale(6) rotate(360deg) translate(-50%, -50%);
    opacity: 0;
    --angle: 360deg;
    border-width: 1.5rem;
  }
}

@keyframes rotate-br {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-width: 1rem;
  }
  to {
    transform: scale(6) rotate(360deg) translate(-50%, -50%);
    opacity: 0;
    --angle: 360deg;
    border-width: 1.5rem;
  }
}

/*  */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
/* ------------------ */
</style>